.eds-input-message-group .eds-color--text-secondary, .eds-color--secondary {
  display: none;
}

.active-tab .eds-tab {
  border-bottom: 2px solid var(--eds-color-text-default);
  color: var(--eds-color-text-default);
}

.non-active-tab {
  cursor: pointer;
  display: block;
  border-bottom: 2px solid rgba(0,0,0,0);
  color: var(--eds-color-text-secondary);
  font: var(--eds-type-subtitle-1);
  padding-bottom: var(--eds-space-8);
  transition: border-bottom-color .25s,color .25s;
}
