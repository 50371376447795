.execution-header {
    font: var(--eds-type-title-4);
}

.execution-header .initiator {
    font: var(--eds-type-title-5);
}

.execution-subheader {
    font: var(--eds-type-title-4);
}

.execution-desc {
    color: #4B5563;
    font: var(--eds-type-title-5)
}

.execution-result-title {
    font: var(--eds-type-title-5)
}
