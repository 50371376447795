.changed {
    border: red 1px solid !important;
}

.action-modal .eds-input-message-group .eds-color--text-secondary, .eds-color--secondary {
    display: none;
}

.watermark {
    position: absolute;
    bottom: 5px;
    right: 10px;
    opacity: 0.5;
    color: slategray;
    font-weight: bold;
    font-size: medium;
}
