/* # Usage
 * use className animated and a sub-class denoting the animation
 */
.animated {
    animation-duration: 200ms;
    animation-fill-mode: both;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}