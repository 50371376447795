.search-header-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.search-main {
    display: flex ;
    flex-direction: column;
    flex-wrap: wrap;
}

.search-main > * {
    flex: 1 1 35px;
}

.search-active, .search-update, .search-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem/* 8px */;
    margin-bottom: 0.5rem/* 8px */;
    gap: 0.5rem/* 8px */;
}